const PROFILE_MODAL_ID = 'change_tag_modal';

(() => {
    const modal$ = $("#" + PROFILE_MODAL_ID);

    if (!modal$.length) {
        return 0;
    }

    // When modal is shown, fill in all values for current profile
    modal$.on('show.bs.modal', e => {
        $('#change_tag_modal_offer_name', modal$).text(e.relatedTarget.dataset.name);
        $("form[name='mapping_change_tag']", modal$).attr('action', e.relatedTarget.dataset.url);
    });
})();
