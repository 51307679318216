import '@epicode/adminlte';
import './components/_profile_modal';
import './components/_client_modal';
import './components/_mapping_modal';
import '../scss/index.scss';

(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="modal"] a').on('click', e => {
        e.stopPropagation();
    });

    $('.table_filter').on('change', 'input[type="checkbox"]', e => {
        $(e.target).parents('form').trigger('submit');
    });

    $('.table_filter').on('click', 'button[name$="[export_button]"]', e => {
        let form$ = $(e.delegateTarget);
        let exportInput$ = form$.find('input[name$="[export]"]');
        exportInput$.val(1);
        form$.trigger('submit');
        exportInput$.val(0);
    });
})();
