const CLIENT_MODAL_ID = 'client_profiles';

(() => {
    let clientName, currentUrl;
    const clientModal$ = $("#" + CLIENT_MODAL_ID);
    const clientModalFilter$ = $('form.table_filter', clientModal$);
    const clientModalOverlay$ = $('.overlay', clientModal$);
    const clientModalTable$ = $('.table', clientModal$);
    const clientModalFooter$ = $('.card-footer', clientModal$);

    if (!clientModal$.length) {
        return 0;
    }

    const updateTable = r => {
        clientModalOverlay$.hide();
        clientModalTable$.html(r.body);
        if (r.footer) {
            clientModalFooter$.show();
        } else {
            clientModalFooter$.hide();
        }
        clientModalFooter$.html(r.footer);
    };

    // When modal is shown, fill in all values for current profile
    clientModal$.on('show.bs.modal', e => {
        currentUrl = e.relatedTarget.dataset.url;
        clientName = e.relatedTarget.dataset.name;
        clientModalFilter$.trigger("reset");
        $('.card-title > strong', clientModal$).text(clientName);
        clientModalOverlay$.show();
        $.get(currentUrl + "?" + clientModalFilter$.serialize(), updateTable);
    });

    // When filter is submitted, instead of trying to reload, update table with ajax
    clientModalFilter$.on('submit', e => {
        e.preventDefault();
        if (currentUrl) {
            clientModalOverlay$.show();
            $.get(currentUrl + "?" + $(e.target).serialize(), updateTable);
        }
    })

    clientModalFilter$.find('input, select, textarea').on('change', (e) => {
        const $filtersForm = $(e.target).parents('form');
        const $pageInput = $filtersForm.find('input[type=hidden][name$="[page]"');
        $pageInput.val(1);
    });

    clientModalFilter$.on('change', 'select', e => {
        $(e.target).parents('form').trigger('submit');
    });

    clientModal$.on('click', '.pagination button', e => {
        e.preventDefault();

        const $pageButton = $(e.target);
        const page = $pageButton.val();
        const $pagination = $pageButton.parents('.table-pagination');
        const formId = $pagination.data('for');
        const $filtersForm = $(`#${formId}`);
        const $pageInput = $filtersForm.find('input[type=hidden][name$="[page]"')
        $pageInput.val(page);

        $filtersForm.trigger('submit');
    });
})();
