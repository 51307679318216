const PROFILE_MODAL_ID = 'handle_profile';
const CONFIRM_MODAL_ID = 'confirm_assign';
const CONFIRM_UNLINK_MODAL_ID = 'confirm_unlink';

(() => {
    let currentUrl, profileId, profileName, profilePortal, profileUrl, clientName, clientId;
    const profileModal$ = $("#" + PROFILE_MODAL_ID);
    const profileModalFilter$ = $('form.table_filter', profileModal$);
    const confirmModal$ = $("#" + CONFIRM_MODAL_ID);
    const unlinkModal$ = $("#" + CONFIRM_UNLINK_MODAL_ID);
    const profileModalOverlay$ = $('.overlay', profileModal$);
    const profileModalTable$ = $('.table', profileModal$);
    const profileModalFooter$ = $('.card-footer', profileModal$);
    const unlinkForm$ = $('form[name="unlink_client"]', profileModal$);

    if (!profileModal$.length) {
        return 0;
    }

    const updateTable = r => {
        profileModalOverlay$.hide();
        profileModalTable$.html(r.body);
        if (r.footer) {
            profileModalFooter$.show();
        } else {
            profileModalFooter$.hide();
        }
        profileModalFooter$.html(r.footer);
    };

    // When modal is shown, fill in all values for current profile
    profileModal$.on('show.bs.modal', e => {
        currentUrl = e.relatedTarget.dataset.url;
        profileId = e.relatedTarget.dataset.id;
        profileName = e.relatedTarget.dataset.name;
        profilePortal = e.relatedTarget.dataset.portal;
        profileUrl = e.relatedTarget.dataset.weburl;
        clientName = e.relatedTarget.dataset.clientname;
        clientId = e.relatedTarget.dataset.clientid;
        $('input#unlink_client_profileId', unlinkForm$).val(profileId);
        profileModalFilter$.trigger("reset")
            .find('input[name="match_client_table[search]"]').val('"' + profileName + '"');
        $('.card-title > strong', profileModal$).text(profileName);
        $('.current strong', profileModal$).text(clientName)
        profileModalOverlay$.show();
        $.get(currentUrl + "?" + profileModalFilter$.serialize(), updateTable);
    });

    // When filter is submitted, instead of trying to reload, update table with ajax
    profileModalFilter$.on('submit', e => {
        e.preventDefault();
        if (currentUrl) {
            profileModalOverlay$.show();
            $.get(currentUrl + "?" + $(e.target).serialize(), updateTable);
        }
    })

    profileModal$.on('click', '.table-row button', e => {
        e.preventDefault();
        let form$ = $('form[name="match_client"]', profileModal$);
        $('input#match_client_clientId', form$).val(e.target.dataset.clientid);
    });

    profileModalFilter$.find('input, select, textarea').on('change', (e) => {
        const $filtersForm = $(e.target).parents('form');
        const $pageInput = $filtersForm.find('input[type=hidden][name$="[page]"');
        $pageInput.val(1);
    });

    profileModalFilter$.on('change', 'select', e => {
        $(e.target).parents('form').trigger('submit');
    });

    profileModal$.on('click', '.pagination button', e => {
        e.preventDefault();

        const $pageButton = $(e.target);
        const page = $pageButton.val();
        const $pagination = $pageButton.parents('.table-pagination');
        const formId = $pagination.data('for');
        const $filtersForm = $(`#${formId}`);
        const $pageInput = $filtersForm.find('input[type=hidden][name$="[page]"')
        $pageInput.val(page);

        $filtersForm.trigger('submit');
    });

    // Do not let bootstrap remove this class as we still have PROFILE_MODAL open
    // And keep padding right the same
    let modalOpenPaddingRight = '0px';

    confirmModal$.on('hide.bs.modal', () => {
        modalOpenPaddingRight = $('body').css('paddingRight');
    });

    confirmModal$.on('hidden.bs.modal', () => {
        $('body').addClass('modal-open').css('paddingRight', modalOpenPaddingRight);
    });

    confirmModal$.on('show.bs.modal', e => {
        $('.client-info strong', confirmModal$).text(e.relatedTarget.dataset.clientname);
        $('.client-info .id span', confirmModal$).text(e.relatedTarget.dataset.clientid);
        $('.profile-info strong', confirmModal$).text(profileName);
        $('.profile-info .portal span', confirmModal$).text(profilePortal);
        if (profileUrl) {
            $('.profile-info strong', confirmModal$).wrapInner(
                '<a target="_blank"></a>'
            );
            $('.profile-info strong a', confirmModal$).attr('href', profileUrl);
        }
    });

    confirmModal$.on('click', 'button#confirm', () => {
        $('button', confirmModal$).prop('disabled', true)
        $('form[name="match_client"]', profileModal$).trigger('submit');
    });

    unlinkModal$.on('hide.bs.modal', () => {
        modalOpenPaddingRight = $('body').css('paddingRight');
    });

    unlinkModal$.on('hidden.bs.modal', () => {
        $('body').addClass('modal-open').css('paddingRight', modalOpenPaddingRight);
    });

    unlinkModal$.on('click', 'button#confirm', () => {
        $('button', unlinkModal$).prop('disabled', true)
        $('form[name="unlink_client"]', profileModal$).trigger('submit');
    });

    unlinkModal$.on('show.bs.modal', () => {
        $('.client-info strong', unlinkModal$).text(clientName);
        $('.client-info .id span', unlinkModal$).text(clientId);
        $('.profile-info strong', unlinkModal$).text(profileName);
        $('.profile-info .portal span', unlinkModal$).text(profilePortal);
        if (profileUrl) {
            $('.profile-info strong', unlinkModal$).wrapInner(
                '<a target="_blank"></a>'
            );
            $('.profile-info strong a', unlinkModal$).attr('href', profileUrl);
        }
    });
})();
